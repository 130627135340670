import React from "react";
import {Col, Row} from "react-bootstrap";
import ansys_icon from "../../Assets/Icons/ansys_v1_mini.svg";
import nx_icon from "../../Assets/Icons/siemens_NX_v1_mini.svg";
import solidworks_icon from "../../Assets/Icons/solidworks_v1_mini.svg";


function EngineeringTools() {
    return (
        <Row style={{justifyContent: "center", paddingBottom: "50px"}}>
            <Col xs={4} md={2} className="tech-icons">
                <img src={ansys_icon} className="tech-icon-images" alt="ansys-icon" style={{height: 1 + 'em'}}/>
                <p className="tech-icons-hidden light-blue">ANSYS <br/> MECHANICAL & FLUENT</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <img src={nx_icon} className="tech-icon-images" alt="nx-icon"/>
                <p className="tech-icons-hidden light-blue">SIEMENS NX</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <p className="tech-icon-text">FEMAP</p>
                <p className="tech-icons-hidden light-blue">SIEMENS<br/>FEMAP & NASTRAN</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <img src={solidworks_icon} className="tech-icon-images" alt="solidworks-icon"/>
                <p className="tech-icons-hidden light-blue">SOLIDWORKS</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <p className="tech-icon-text">TDK</p>
                <p className="tech-icons-hidden light-blue">NASA<br/>TWO-DIMENSIONAL<br/>KINETICS</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <p className="tech-icon-text">RTE</p>
                <p className="tech-icons-hidden light-blue">NASA<br/>ROCKET THERMAL<br/>EVALUATION</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <p className="tech-icon-text">ROCCID</p>
                <p className="tech-icons-hidden light-blue">NASA<br/>ROCKET COMBUSTOR<br/>INTERACTIVE DESIGN</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <p className="tech-icon-text">NEK5000</p>
                <p className="tech-icons-hidden light-blue">NEK5000 OPENSOURCE<br/>CFD CODE</p>
            </Col>
        </Row>
    );
}

export default EngineeringTools;
