import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {FaLinkedinIn} from "react-icons/fa";

function Home2() {
    return (
        <Container fluid className="home-about-section" id="about">
            <Container>
                <Row>
                    <Col md={8} className="home-about-description">
                        <h1 style={{fontSize: "2.6em"}}>
                            ALLOW ME TO <span className="blue"> INTRODUCE </span> MYSELF
                        </h1>
                        <p className="home-about-body">
                            I love a good design challenge and creating new things! There are always bumps along the
                            way, but in the end a lot is learned and something special produced.
                            <br/>
                            <br/>I am proficient in some classic design packages
                            <i>
                                <b className="blue"> SolidWorks, NX, & Fusion 360. </b>
                            </i>
                            <br/>
                            <br/>
                            My fields of interest are anything technology related, with a special focus on &nbsp;
                            <i>
                                <b className="blue">Commercial Space Exploration</b> & <b className="blue">Green
                                Energy</b> and areas
                                adjacent to{" "}
                                <b className="blue">
                                    Computation & BioTech.
                                </b>
                            </i>
                            <br/>
                            <br/>
                            Whenever possible, I also extend my design work by applying my passion for software
                            development
                            with <b className="blue">Python</b> to accelerate my ability to design and analyze new and
                            critical features.
                        </p>
                    </Col>
                    <Col md={4} className="myAvtar">
                        <Tilt>
                            <img src={myImg} className="img-fluid" alt="avatar"/>
                        </Tilt>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="home-about-social">
                        <h1>FIND ME ON</h1>
                        <p>
                            Feel free to <span className="blue">connect </span>with me
                        </p>
                        <ul className="home-about-social-links">
                            <li className="social-icons">
                                <a
                                    href="https://www.linkedin.com/in/rweekes/"
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-colour  home-social-icons"
                                >
                                    <FaLinkedinIn/>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Home2;
