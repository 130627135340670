import React from "react";
import {Col, Row} from "react-bootstrap";
import {SiFortran, SiJavascript} from "react-icons/si";
import {DiGit, DiPython} from "react-icons/di";
import { PiFileSqlLight } from "react-icons/pi";
import matlab_icon from "../../Assets/Icons/matlab_v1_mini.svg";

function ProgrammingToolsLangs() {
    return (
        <Row style={{justifyContent: "center", paddingBottom: "50px"}}>
            <Col xs={4} md={2} className="tech-icons">
                <DiPython className="tech-icon-react-images"/>
                <p className="tech-icons-hidden light-blue">PYTHON</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <img src={matlab_icon} className="tech-icon-images light-blue" alt="matlab-icon"/>
                <p className="tech-icons-hidden light-blue">MATLAB</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <SiJavascript className="tech-icon-react-images"/>
                <p className="tech-icons-hidden light-blue">JAVASCRIPT</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <PiFileSqlLight className="tech-icon-react-images"/>
                <p className="tech-icons-hidden light-blue">SQL</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <SiFortran className="tech-icon-react-images"/>
                <p className="tech-icons-hidden light-blue">FORTRAN</p>
            </Col>
            <Col xs={4} md={2} className="tech-icons">
                <DiGit className="tech-icon-react-images"/>
                <p className="tech-icons-hidden light-blue">GIT</p>
            </Col>
        </Row>
    );
}

export default ProgrammingToolsLangs;
