import React, {useEffect, useState} from "react";
import {Container, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import pdf from "../../Assets/ReileyWeekes_Resume_OnlineOnly.pdf";
import {AiOutlineDownload} from "react-icons/ai";
import {Document, Page, pdfjs} from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

function ResumeNew() {
    const [width, setWidth] = useState(1200);

    useEffect(() => {
        setWidth(window.innerWidth);
    }, []);

    return (
        <div>
            <Container fluid className="resume-section">
                <Particle/>
                <Row style={{justifyContent: "center", position: "relative"}}>
                    <Button
                        variant="primary"
                        href={pdf}
                        target="_blank"
                        style={{maxWidth: "250px"}}
                    >
                        <AiOutlineDownload/>
                        &nbsp;Download Resume
                    </Button>
                </Row>

                <Row className="resume">
                    <Document file={pdf} className="d-flex justify-content-center">
                        <Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={1}
                              scale={width > 786 ? 1.7 : 0.6}/>
                    </Document>
                    {/*<Document file={pdf} className="d-flex justify-content-center">*/}
                    {/*    <Page renderAnnotationLayer={false} renderTextLayer={false} pageNumber={2}*/}
                    {/*          scale={width > 786 ? 1.7 : 0.6}/>*/}
                    {/*</Document>*/}
                </Row>

                <Row style={{justifyContent: "center", position: "relative"}}>
                    <Button
                        variant="primary"
                        href={pdf}
                        target="_blank"
                        style={{maxWidth: "250px"}}
                    >
                        <AiOutlineDownload/>
                        &nbsp;Download Resume
                    </Button>
                </Row>
            </Container>
        </div>
    );
}

export default ResumeNew;
