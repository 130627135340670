import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import Particle from "../Particle";
import EngineeringTools from "./EngineeringTools";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about-image.svg";
import ProgrammingToolsLangs from "./ProgrammingToolsLangs";

function About() {
    return (
        <Container fluid className="about-section">
            <Particle/>
            <Container>
                <Row style={{justifyContent: "center", padding: "10px"}}>
                    <Col
                        md={7}
                        style={{
                            justifyContent: "center",
                            paddingTop: "30px",
                            paddingBottom: "50px",
                        }}
                    >
                        <h1 style={{fontSize: "2.1em", paddingBottom: "20px"}}>
                            Who <strong className="blue">AM</strong> I
                        </h1>
                        <Aboutcard/>
                    </Col>
                    <Col
                        md={5}
                        style={{paddingTop: "120px", paddingBottom: "50px"}}
                        className="about-img"
                    >
                        <img src={laptopImg} alt="about" className="img-fluid"/>
                    </Col>
                </Row>
                <h1 className="project-heading">
                    Engineering <strong className="blue">Software </strong> I Use
                </h1>

                <EngineeringTools/>

                <h1 className="project-heading">
                    Programming <strong className="blue"> Languages & Tools</strong> I Use
                </h1>
                <ProgrammingToolsLangs/>
            </Container>
        </Container>
    );
}

export default About;
