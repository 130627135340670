import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {CgGitFork} from "react-icons/cg";
import Button from "react-bootstrap/Button";
import { AiOutlineSketch } from "react-icons/ai";

function Footer() {
    let date = new Date();
    let year = date.getFullYear();
    return (
        <Container fluid className="footer">
            <Row>
                <Col md="4" className="footer-copywright">
                    <h3>Based on a template designed by Soumyajit Behera&nbsp;&nbsp;
                        <Button
                            href="https://github.com/soumyajit4419/Portfolio"
                            target="_blank"
                            className="fork-btn-inner"
                        >
                            <CgGitFork style={{fontSize: "1.0em"}}/>{" "}
                        </Button></h3>
                </Col>
                <Col md="4" className="footer-copywright">
                    <h3>Avatar modified from the work of Cécile L. Parker&nbsp;&nbsp;
                        <Button
                            href="https://www.sketchappsources.com/free-source/3635-stylish-user-avatars-sketch-freebie-resource.html"
                            target="_blank"
                            className="fork-btn-inner"
                        >
                            <AiOutlineSketch style={{fontSize: "1.0em"}}/>{" "}
                        </Button></h3>
                </Col>
                <Col md="4" className="footer-copywright">
                    <h3>Vector illustrations modified from the work of Andy Dao&nbsp;&nbsp;
                        <Button
                            href="https://www.sketchappsources.com/free-source/4341-beautiful-illustrations-sketch-freebie-resource.html"
                            target="_blank"
                            className="fork-btn-inner"
                        >
                            <AiOutlineSketch style={{fontSize: "1.0em"}}/>{" "}
                        </Button></h3>
                </Col>
            </Row>
            <Row>
                <Col md="12" className="footer-copywright">
                    <h3><center>Copyright © {year}</center></h3>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
