import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import satellite from "../../Assets/Projects/3u_satellite.png";
import bread_pizza from "../../Assets/Projects/bread_pizza.png";
import groot from "../../Assets/Projects/light-up_baby_groot.png";
import madvent from "../../Assets/Projects/madvent.png";
import nemesis from "../../Assets/Projects/nemesis.png";
import sailing from "../../Assets/Projects/sailing_device.png";
import flud from "../../Assets/Projects/wincf_flud.png";
import {Link} from "react-router-dom";

function Projects() {
    return (
        <Container fluid className="project-section">
            <Particle/>
            <Container>
                <h1 className="project-heading">
                    Some Fun <strong className="blue">Projects </strong>
                </h1>
                <p style={{color: "white"}}>
                    Here are a few fun things I've made and projects I've worked on to make up for the fact that most of
                    my professional work comprises confidential projects that don't allow me to share photos publicly.
                    See my <Link to="/Resume">Resume</Link> for descriptions of those projects.
                </p>
                <Row style={{justifyContent: "center", paddingBottom: "10px"}}>
                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={groot}
                            title="Light-Up Baby Groot"
                            description="Stand for a translucent 3D printed Baby Groot figurine. LEDs illuminate a plate of diffusion acrylic when a sufficient weight is applied to the surface."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={madvent}
                            title="MADVent"
                            description="A basic ventilator designed during the initial throes of the COVID-19 lockdown. Intended to be made with readily available parts. Designed and tested in partnership with UC San Diego's Medical School."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={nemesis}
                            title="'Nemsis' Kera-Lox Thrust Chamber"
                            description="500 lbf class pressure-fed thrust chamber. Printed in a single piece with zero post-machining. Image is after the first hotfire."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={flud}
                            title="WinCF FLUD (Fluid Loading Utility Device)"
                            description="Suite of devices developed for researchers at the Skaggs School of Pharmacy to improve the manual process of loading microcapillary tubes. In head-to-head trials these tools cut loading and prep time by at least 50%."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={sailing}
                            title="GPS, Compass, & Anemometer"
                            description="Prototype designed to hold a GPS, Compass, and Anemometer in a single ergonomic housing. Intended to be an all-in-one tool for sailors to understand their current position, heading, and windspeed."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={satellite}
                            title="3U Satellite with Integrated Propulsion"
                            description="3U sized micro-sat designed as show-case of a newly designed 1U coldgas propulsion system wherein the tank & nozzle are printed together as single unit (seen near the attachment point of the solar panels)."
                        />
                    </Col>

                    <Col md={4} className="project-card">
                        <ProjectCard
                            imgPath={bread_pizza}
                            title="Homemade Bread & Pizza"
                            description="One of the best bread loaves I've ever made pictured next to a pizza made with the same homemade dough."
                        />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Projects;
