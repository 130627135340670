import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import home_image from "../../Assets/home-image.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import rocket from "../../Assets/rocket_v1_mini.svg";

function Home() {
    return (
        <section>
            <Container fluid className="home-section" id="home">
                <Particle/>
                <Container className="home-content">
                    <Row>
                        <Col md={7} className="home-header">
                            <h1>
                                <span className="heading">Hi There!{" "}</span>
                                <span className="rocket-launcher" role="img" aria-labelledby="rocket">
                                <span className="rocket-container" role="img" aria-labelledby="rocket">
                                <img src={rocket} className="rocket" alt="rocket-icon"/>
                                </span>
                                </span>
                                <span className="heading-name">
                                I'M
                                <strong className="main-name"> REILEY WEEKES</strong>
                                </span>
                            </h1>
                            <div style={{padding: 50, textAlign: "left"}}>
                                <Type/>
                            </div>
                        </Col>

                        <Col md={5} style={{paddingBottom: 25, paddingTop: 25}}>
                            <img
                                src={home_image}
                                alt="home pic"
                                className="home-image"
                                style={{maxHeight: "450px"}}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Home2/>
        </section>
    );
}

export default Home;
